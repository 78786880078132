import { RefObject, useEffect, useRef, useState } from 'react';
import Cookie from 'js-cookie';

import { DMEXCO } from './constants';
import { useThemeStore } from './stores';

export const useIsIFrameLoaded = (iframeRef: RefObject<HTMLIFrameElement>): boolean => {
  const [isIFrameLoaded, setIsIFrameLoaded] = useState(false);
  const iframeCurrent = iframeRef.current;

  useEffect(() => {
    iframeCurrent?.addEventListener('load', () => {
      return setIsIFrameLoaded(true);
    });

    return () => {
      iframeCurrent?.removeEventListener('load', () => {
        return setIsIFrameLoaded(false);
      });
    };
  }, [iframeCurrent]);
  return isIFrameLoaded;
};

export const useRefDimensions = <T extends HTMLElement>() => {
  const ref = useRef<T>(null);

  const [rect, setRect] = useState<DOMRect | null>(null);

  useEffect(() => {
    if (ref.current) {
      setRect(ref.current.getBoundingClientRect());
    }
  }, []);

  return { rect, ref };
};

export const useDmexco = () => {
  const theme = useThemeStore((state) => {
    return state.theme;
  });
  const isDmexco = Cookie.get(DMEXCO) === 'true';

  useEffect(() => {
    if (isDmexco) {
      document.body.classList.add(DMEXCO);
    }

    return () => {
      document.body.classList.remove(DMEXCO);
    };
  }, [isDmexco, theme]);

  return isDmexco;
};
