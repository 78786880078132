import { useMemo } from 'react';

import { E_AD_CATEGORY_NAME, E_EFFECT, E_PREVIEW_MODE } from '@common/enums';
import {
  addSuffixToPreviewUrl,
  getAdCategoryBySlotId,
  getProductNameFromAdData,
  isDesktopOnly,
  isMobileOnly,
} from '@common/helpers';
import { E_AD_PRODUCT_KEY, type TAdData, TAdDataBase, type TSlotId } from '@common/types';
import { generateAdPreviewUrl, getPreviewTemplateUrl } from '@pages/AdPreview/helpers';

import { DEVICE_RELATED_EXCLUDE_EFFECTS, EFFECT_TITLE_NAME, NARROW_DESKTOP_PREVIEW_EFFECTS } from './constants';
import { TLinkData } from './types';

const getEffectSlidesData = (adData: TAdData | null | undefined, slotId: TSlotId) => {
  const getTemplateUrls = (previewMode: E_PREVIEW_MODE, effectName: E_EFFECT | null) => {
    let base = null;
    const src = `${getPreviewTemplateUrl(previewMode, adData?.previewWebsiteLayout)}`;
    const extraParams = [];

    if (effectName) {
      extraParams.push(`effect=${effectName}`);
      if (previewMode === E_PREVIEW_MODE.DESKTOP && NARROW_DESKTOP_PREVIEW_EFFECTS.includes(effectName)) {
        extraParams.push('desktopWidth=640');
      }
      if (!DEVICE_RELATED_EXCLUDE_EFFECTS[previewMode].includes(effectName)) {
        const fullUrl = addSuffixToPreviewUrl(`${src}/${slotId}`, extraParams);
        base = {
          baseUrl: src, // user should see creative's animation from the beginning so let's show a blank page first
          fullUrl,
        };
      }
    } else if (adData?.productKey === E_AD_PRODUCT_KEY.YUVS) {
      extraParams.push(`product=${E_AD_PRODUCT_KEY.YUVS.toLowerCase()}`);
      const fullUrl = addSuffixToPreviewUrl(`${src}/${slotId}`, extraParams);
      base = {
        baseUrl: src,
        fullUrl,
      };
    }
    return base;
  };

  // Effects
  const effectsData: TLinkData[] = [];
  const hasMobilePrimeScroller = adData?.allowEffects?.includes(E_EFFECT.PRIME_SCROLLER_MOBILE);
  const hasDesktopPrimeScroller = adData?.allowEffects?.includes(E_EFFECT.PRIME_SCROLLER_DESKTOP);

  if (hasMobilePrimeScroller || hasDesktopPrimeScroller) {
    effectsData.push({
      slotId,
      [E_PREVIEW_MODE.MOBILE]: hasMobilePrimeScroller
        ? getTemplateUrls(E_PREVIEW_MODE.MOBILE, E_EFFECT.PRIME_SCROLLER_MOBILE)
        : null,
      [E_PREVIEW_MODE.DESKTOP]: hasDesktopPrimeScroller
        ? getTemplateUrls(E_PREVIEW_MODE.DESKTOP, E_EFFECT.PRIME_SCROLLER_DESKTOP)
        : null,
      name: 'YOC Prime Scroller',
    });
  }

  effectsData.push(
    ...(adData?.allowEffects
      ?.filter((effectName) => {
        return effectName !== E_EFFECT.PRIME_SCROLLER_MOBILE && effectName !== E_EFFECT.PRIME_SCROLLER_DESKTOP;
      })
      .map((effectName) => {
        return {
          slotId,
          [E_PREVIEW_MODE.MOBILE]: getTemplateUrls(E_PREVIEW_MODE.MOBILE, effectName),
          [E_PREVIEW_MODE.DESKTOP]: getTemplateUrls(E_PREVIEW_MODE.DESKTOP, effectName),
          name: EFFECT_TITLE_NAME[effectName] || effectName,
        };
      }) || [])
  );

  // YUVS
  const yuvsData: TLinkData[] = [];
  if (adData?.productKey === E_AD_PRODUCT_KEY.YUVS) {
    yuvsData.push({
      slotId,
      [E_PREVIEW_MODE.MOBILE]: getTemplateUrls(E_PREVIEW_MODE.MOBILE, null),
      [E_PREVIEW_MODE.DESKTOP]: getTemplateUrls(E_PREVIEW_MODE.DESKTOP, null),
      name: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_SOLUTION,
    });
  }

  return [...effectsData, ...yuvsData];
};

// Not totally removing in case it is needed.
export const useEffectSlidesData = (adData: TAdData | null | undefined, slotId: TSlotId) => {
  return useMemo(() => {
    return getEffectSlidesData(adData, slotId);
  }, [adData, slotId]);
};

export const useAdSlidesData = (adsData: TAdDataBase) => {
  return useMemo(() => {
    // loop through ads and loop through effects if any ad has multiple effects
    // and create slides
    const adsSlideData: TLinkData[] = [];
    if (adsData) {
      const slotIdList = Object.keys(adsData);
      slotIdList.forEach((slotId) => {
        const adData = adsData[slotId];
        if (adData) {
          if (adData.allowEffects && Array.isArray(adData.allowEffects) && adData.allowEffects.length > 0) {
            adsSlideData.push(...getEffectSlidesData(adData, slotId));
          } else {
            // Conditionally add mobile/desktop urls depending on ad support.
            adsSlideData.push({
              slotId,
              [E_PREVIEW_MODE.MOBILE]: isDesktopOnly(adData)
                ? null
                : generateAdPreviewUrl(E_PREVIEW_MODE.MOBILE, adData, slotId, true),
              [E_PREVIEW_MODE.DESKTOP]: isMobileOnly(adData)
                ? null
                : generateAdPreviewUrl(E_PREVIEW_MODE.DESKTOP, adData, slotId, true),
              name: getAdCategoryBySlotId(slotId) || getProductNameFromAdData(adData) || '',
            });
          }
        } else {
          // if there is no data found with the corresponding slot id use not found layout url
          adsSlideData.push({
            slotId,
            [E_PREVIEW_MODE.DESKTOP]: null,
            [E_PREVIEW_MODE.MOBILE]: {
              baseUrl: '',
              fullUrl: '',
            },
            name: '⚠️ slotId: ' + slotId,
          });
        }
      });
    }
    return adsSlideData;
  }, [adsData]);
};
