import { FunctionComponent, memo, useCallback, useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { type Route } from 'type-route';
import { Modal, Typography } from 'yoc-ui-library';

import { E_AD_CATEGORY_NAME, E_COUNTRY_CODE, E_PREVIEW_MODE, E_PREVIEW_THEME } from '@common/enums';
import { useDmexco } from '@common/hooks';
import { setIsModalOpen, setIsTurnModal, useModalStore } from '@common/stores';
import type { TSlotId } from '@common/types';
import Header from '@components/Header';
import Page from '@components/Page';
import { ADS_COLLECTION } from '@configs/index';
import { routes, useRoute } from '@navigation/router';
import { getPreviewTemplateUrl } from '@pages/AdPreview/helpers';
import { getDefaultSlotId } from '@pages/Showroom/helpers';
import Menu from '@pages/Showroom/Menu';

import style from '@pages/Showroom/styles.module.scss';

const MobileShowroom: FunctionComponent = () => {
  const route = useRoute() as Route<typeof routes.collection>;
  const collectionId = route.params.collectionId || E_COUNTRY_CODE.GERMANY;

  const isDMEXCOMode = useDmexco();

  const collectionData = ADS_COLLECTION[collectionId];
  const initSlotId = getDefaultSlotId(collectionData);

  // TODO: if slotId in url is invalid, activeSlotId stays being invalid url
  // even after being redirected to the default ad url.
  const activeSlotId = route.params.slotId ?? initSlotId;

  const landscape = window.matchMedia('(orientation: landscape)');
  const { isModalOpen, isTurnModal, modalSlotId } = useModalStore();

  const modalContent = isTurnModal ? (
    <p>To view this desktop case, rotate your screen 90° degrees to landscape mode for an immersive experience.</p>
  ) : (
    <>
      <p>
        This format is designed for a larger screen to provide a richer experience. Please open this link on your laptop
        to fully enjoy the content.
      </p>
      <Typography.Paragraph copyable>showroom.yoc.com</Typography.Paragraph>
    </>
  );

  const onMobileMenuChange = useCallback(
    (
      slotId: TSlotId | undefined,
      category: E_AD_CATEGORY_NAME,
      isDesktopCase: boolean // displayMode, needs changing when displayMode is gone
    ) => {
      const GALLERY_CATEGORIES = [
        E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_SOLUTION,
        E_AD_CATEGORY_NAME.YOC_UNIVERSAL_DISPLAY_SOLUTION,
        E_AD_CATEGORY_NAME.YOC_SKIN,
      ];
      if (slotId) {
        // add selected `slotId` to routes history, to have a correct state if Back button was used
        routes.collection({ collectionId: route.params.collectionId, slotId }).push();

        if (isDMEXCOMode) {
          routes.preview({ slotId }).push();
          return;
        }

        if (isMobileOnly && (GALLERY_CATEGORIES.includes(category) || isDesktopCase)) {
          if (isDesktopCase && category !== E_AD_CATEGORY_NAME.YOC_SKIN) {
            setIsTurnModal(true);
          }
          setIsModalOpen(true);
        } else {
          // redirect to BE with site's `Back` button enabled
          window.location.href = `${getPreviewTemplateUrl(
            E_PREVIEW_MODE.MOBILE,
            E_PREVIEW_THEME.YOC
          )}/${slotId}?nomenu`;
          return;
        }
      }
    },
    [route.params.collectionId, isDMEXCOMode]
  );

  // TODO: remove!!! incorrect behavior on Main DESKTOP page
  useEffect(() => {
    if (E_PREVIEW_THEME.YOC && isMobileOnly) {
      document.body.classList.add('mobile');
      document.body.classList.remove('preview-mobile');
    }

    return () => {
      if (!(E_PREVIEW_THEME.YOC && isMobileOnly)) {
        document.body.classList.remove('mobile');
      }
    };
  }, []);

  // a lot of questions...
  useEffect(() => {
    const handleOrientationChange = () => {
      if (landscape.matches) {
        setIsModalOpen(false);
        routes.websitePreview({ slotId: modalSlotId }).push();
      }
    };
    if (isModalOpen && isTurnModal) {
      landscape.addEventListener('change', handleOrientationChange);
    }

    return () => {
      if (landscape) {
        landscape.removeEventListener('change', handleOrientationChange);
      }
    };
  }, [isModalOpen, isTurnModal, landscape, modalSlotId]);

  return (
    <Page forceTheme>
      <div className={`${style.page} ${style.mobile}`}>
        <Header
          tag={collectionData.tag}
          navigation={collectionData.type === 'collection' ? false : undefined}
        />
        <div className={style.aside}>
          <Modal
            className={`modal ${isTurnModal ? 'desktop-turn' : 'solutions'}`.trim()}
            centered
            open={isModalOpen}
            onCancel={() => {
              setIsModalOpen(false);
              setIsTurnModal(false);
            }}
            onOk={() => {
              setIsModalOpen(false);
              if (isTurnModal) {
                routes.websitePreview({ slotId: modalSlotId }).push();
              }
            }}
          >
            {modalContent}
          </Modal>
          <Menu
            isMobile
            key={collectionId} // refresh Menu state if `collectionId` was changed
            creatives={collectionData.ads}
            activeSlotId={activeSlotId}
            onCreativeChange={onMobileMenuChange}
          />
        </div>
      </div>
    </Page>
  );
};

export default memo(MobileShowroom);
