import { E_AD_CATEGORY_NAME, E_COUNTRY_CODE } from '@common/enums';
import type { TimeZone } from '@common/types';

export const ADS_HOST_PRODUCTION = 'c.yoc.com';
export const ADS_HOST_STAGING = 'c-staging.yoc.com';
export const ADS_HOST = process.env.ADS_ENVIRONMENT === 'production' ? ADS_HOST_PRODUCTION : ADS_HOST_STAGING;

export const AVAILABLE_COUNTRIES = Object.values(E_COUNTRY_CODE).sort();
export const DEFAULT_COUNTRY_CODE = E_COUNTRY_CODE.GERMANY;

export const NON_MOBILE_CATEGORIES = [
  E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_AD,
  E_AD_CATEGORY_NAME.YOC_SITEBAR,
  E_AD_CATEGORY_NAME.YOC_UNIVERSAL_DISPLAY_SOLUTION,
  E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_SOLUTION,
];

export const TIME_ZONES: TimeZone[] = [
  {
    timeZone: 'Europe/Vienna',
    code: E_COUNTRY_CODE.AUSTRIA,
  },
  {
    timeZone: 'Europe/Berlin',
    code: E_COUNTRY_CODE.GERMANY,
  },
  {
    timeZone: 'Europe/Busingen',
    code: E_COUNTRY_CODE.GERMANY,
  },
  {
    timeZone: 'Europe/Zurich',
    code: E_COUNTRY_CODE.SWITZERLAND,
  },
  {
    timeZone: 'Europe/Warsaw',
    code: E_COUNTRY_CODE.POLAND,
  },
  {
    timeZone: 'Europe/Helsinki',
    code: E_COUNTRY_CODE.FINLAND,
  },
];

export const DMEXCO = 'dmexco';

export const SOURCE_OF_ASSET_TO_PRODUCT_NAME = {
  'disabled': undefined,
  'auto': undefined,
  'default': undefined,
  'celtra-banner': 'YOC Banner Classic',
  'celtra-banner-sticky': 'YOC Mystery Scroller®',
  'celtra-interscroller': 'YOC Understitial Ad®',
  'celtra-interstitial': 'YOC Mystery Ad®',
  'celtra-background-skin': 'YOC Desktop Skin',
  'adtron-banner': 'YOC Banner Classic',
  'adtron-inline': undefined,
  'adtron-sticky': 'YOC Mystery Scroller®',
  'adtron-interstitial': 'YOC Mystery Ad®',
  'unknown': undefined,
};

