import { FunctionComponent, memo, useCallback, useRef } from 'react';

import { usePostMessageHandler } from './hooks';
import { IPreviewScreenProps, TScrollbarWidthMessage } from './types';

import style from './styles.module.scss';

const PreviewScreen: FunctionComponent<IPreviewScreenProps> = (props) => {
  const { mode, url } = props;

  const iframeRef = useRef<HTMLIFrameElement>(null);

  // TODO: handle wrong URL
  // TODO: handle loading? state
  // TODO: combine `processMessage` and `usePostMessageHandler` into one hook
  const processMessage = useCallback((event: MessageEvent<TScrollbarWidthMessage>) => {
    const iframe = iframeRef.current;

    if (
      iframe && iframe.contentWindow === event.source &&
      event.data && typeof event.data === 'object' && event.data.msg === 'hideScrollbar'
    ) {
      const fakeScroll = event.data.scrollbarWidth > 0;

      // sent message to fake-scroll.js
      // TODO: use real `adType`
      iframe.contentWindow?.postMessage({ msg: 'init', fakeScroll, mobileLayout: true, adType: 'ybc' }, '*');

      // check for fake-scroll only if tab is active (to avoid width calculations on background tabs)
      if (!document.hidden && fakeScroll) {
        iframe.style.width = `calc(100% + ${event.data.scrollbarWidth}px)`;
      }
    }
  }, [iframeRef]);

  usePostMessageHandler(processMessage);

  // TODO: do we need it now?
  if (!url || url === '') {
    return (
      <div className="main">
        <span className={style['large-text']}>⚠️</span>
        <p>No data found</p>
      </div>
    );
  }

  const IframeElement = (
    <iframe
      key={url} // fixes browser history flood
      src={url}
      ref={iframeRef} // fake scroll related
      title="Ad Preview"
      allowFullScreen
      allow="geolocation"
      sandbox="allow-same-origin allow-forms allow-scripts allow-popups"
      data-testid="previewIframe"
      data-cmp-ab="1" // In order to prevent CMP script blocking iframe loading
    />
  );

  // TODO: Do we need it?
  // if (isMobileOnly && mode === 'mobile' && !isDMEXCOMode) {
  //   return IframeElement;
  // } else { }
  return (
    <div
      className={style[mode]}
      key={mode}
    >
      <div className={style.frame}>
        <div className={style['frame-holder']}>
          <div className={style.screen}>
            <div className={style['screen-holder']}>{IframeElement}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

PreviewScreen.displayName = 'PreviewScreen';

export default memo(PreviewScreen);
