import { E_AD_CATEGORY_NAME } from '@common/enums';
import type { TCreative } from '@common/types';

export const ADS_COLLECTION_SE: TCreative[] = [
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_SOLUTION,
    slotId: '7anG-FcGu-jCbu',
    title: 'Vertical Video',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/landrover-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_SOLUTION,
    slotId: 'Ocjf-9yof-AVug',
    title: 'Background Image',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/samsung-logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_SOLUTION,
    slotId: 'Ex1h-nAys-jOrF',
    title: 'Background Image',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/lavazza-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_SOLUTION,
    slotId: 'bRgX-cnLe-EZu4',
    title: 'Blurred Background',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/BMW_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_SOLUTION,
    slotId: 'FQx5-aFMx-qQas+rzh3-0Nxp-2FBn',
    title: 'Background Image',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/SE/Logo/Adidas_Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_DISPLAY_SOLUTION,
    slotId: 'XYAr-I34j-TyhE',
    title: 'Gallery',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/gigantti_fi.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_DISPLAY_SOLUTION,
    slotId: 'hhPU-DLLb-ffb1+Ojn1-xhad-LkNQ+xvdm-HtOM-DDgo+xpq5-SSfh-bJ8p+WeIO-0hES-fB5g+eRIx-yg20-L8om+UUy2-zhng-w1RB',
    title: 'Tap + Scroll-reactive',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/McD_Logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_DISPLAY_SOLUTION,
    slotId: '8KGg-fsPR-iHey+5S6w-zFzx-ZpGz+W8y9-U2GB-PENA',
    title: 'Mini Game',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/Sprite_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'XRDg-Iu7p-AAxs',
    title: 'Sliderbar',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/DISNEY.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'dZ6U-Ol8B-aUMH',
    title: 'Mini Game',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/NESPRESSO.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'v6WY-IYHh-KIo6',
    title: 'Dynamic Battery',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/volvo_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'mOdg-aPUf-Qbh8',
    title: 'Tap',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/samsung-logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'fHsN-8Yzw-IKPO',
    title: '360° Swipe',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/logo/Kia_biale.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'HStN-A7H3-t6r4',
    title: '3D',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/PL/Logo/Coca_Cola_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'FLEi-ufjC-FRwA',
    title: 'Augumented Reality',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/logo/SiemensLogoBiale.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'X15s-sC4X-0D85',
    title: 'Reactive + Hot Spots',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/logo/reno.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'a5lM-II4w-dj7s',
    title: 'Video',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/AMEX.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'qBxh-IGVz-71qE',
    title: 'Gallery',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/LEGO.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_ZOOM_AD,
    slotId: 'dmy5-oG8P-PBiL',
    title: 'Background Image',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/sport2000-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_ZOOM_AD,
    slotId: 'BdyT-wSuL-BAUF',
    title: 'Background Image',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/donna-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_ZOOM_AD,
    slotId: '7R79-afk0-0YFb',
    title: 'Background Image',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/samsung_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'VLkU-ZwNN-cELZ',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/LANCOME.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: '2Aqf-9gXb-OhIz',
    title: 'Video',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/MBC.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'iLV5-T54k-VagN',
    title: 'Video',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/lidl-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'f6Em-FIPE-yQl2',
    title: 'Wipe',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/Telia_Company_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: '3GrG-pQ63-Bi93',
    title: 'Vertical Video',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/nivea-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'GFIs-qYuQ-RyDg',
    title: 'Video Integration',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/apollo_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'pLlw-09sW-zBJX',
    title: 'Quiz',
    displayMode: 'desktop',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/VW.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'KG9D-iuyS-rauZ',
    title: 'Story',
    displayMode: 'desktop',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/samsung_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'sJI4-FP4F-Im6N',
    title: 'Poll',
    displayMode: 'desktop',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/PL/SHOWROOM/compressed/logo/seth.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
    slotId: 'GeDQ-mH1H-qQYn',
    title: 'Branded Player',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/nivea-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
    slotId: '2WEq-tNsk-r8VQ',
    title: 'Inline to Sticky',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/AMAZON-PRIME-VIDEO.svg',
  },
];
